html,
body,
#root {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

:target::before {
	content: "";
	display: block;
	height: 106px;
	margin: -106px 0 0;
}

#tsparticles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}
